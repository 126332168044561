@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
};

:root {
    line-height: 1.4;
	font-family: 'Nunito', sans-serif;

    /* LANDING */
    // --backLanding:linear-gradient(to left bottom, #ff0000, #f70047, #db0072, #ad0d90, #74369d, #694ca6, #625dab, #606cac, #8b8ec0, #b3b2d5, #d9d8ea, #ffffff);
    --backLanding:linear-gradient(37deg, rgb(242, 242, 242) 0%, rgb(199, 158, 127) 40%, rgb(201, 33, 39) 100%);

    /* VARIABLES */
    --accentBackground:#0000001a;
    --background1: #32383e;
    --background2: #f0f2f5;
    --background3: #c4c4c491;
    --background4: #ffffff;

    --acc-color: #d32058;
    --pp-color:#163f77;

    --color1: #1a1a1a;
    --color2: #283891;
    --color3: linear-gradient(0deg,#3358f4,#1d8cf8);
    --color4: #ffffff;
   
    --text1:#2c3c57;
	--text2:#60657a;
	--text3:#9798a1;
    --text4:#c1c4d1;
	--text5:#ffffff;

    --err:#e91e63;
    --success:#5fd075;
	--warning: #f89406;
    --info: #06ccf8;

    --fontS: 12px;
    --fontM: 14px;
    --fontL: 18px;
    --fontXL: 20px;
    --fontXXL: 25px;
    --fontFamily: 'Nunito', sans-serif;

    --border:#202b3f54;
    --border2:#c1c4d157;

	--blur: blur(3px);
	--hover:  #79797963;
	--shadow: 2px 2px 6px rgb(0 0 0 / 10%);
    --radius: 10px;
    
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	--transition: 0.25s;
	--transition-quick: 0.1s;

	color: var(--text1);
};

// body{
//     background-image:var(--backLanding);
//  };


h1,
h2,
h3,
h4,
h5,
p,
label,
button {
	font-weight: 500;
    letter-spacing: 1px;
    color: var(--text5);
    font-family: var(--fontFamily);
};

input,
textarea{
    font-family: var(--fontFamily);
};

label,
button {
	cursor: pointer;
	user-select: none;
};

p {
	color: var(--text2);
    font-size: 18px;
    line-height: 1.6em;
};

h1 {
	font-size: 2.5rem;
};

h2 {
	font-size: 1.85rem;
};

h3 {
	font-size: 1.5rem;
};
h4 {
	font-size: 1.3rem;
};

h5,
small {
	font-size: 1rem;
};

h6 {
	font-size: 0.7rem;
};

/* SCROLL BAR */
::-webkit-scrollbar {
    /* background-color:transparent; */
    /* overflow: visible; */
    width: 10px;
    // visibility: hidden;
    // background-color: red;
};

::-webkit-scrollbar-thumb {
    /* overflow: visible; */
    background: #636363b1;
    border: none;
    border-radius: 10px;
        &:hover{
            background: #636363;
            width: 15px;
                &::-webkit-scrollbar{
                    width: 15px;
                }
        }
};

 ::-webkit-scrollbar:horizontal {
    height: 10px;
};

 ::-webkit-scrollbar-button {
	background: transparent;
    width: 5px;
    height: 5px;
    // background-color: turquoise;
};

 ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    /* background-color: turquoise; */
    // background-color: var(--background2);
};

::-webkit-scrollbar-corner{
	background: transparent;
};